<template>
    <main>
      <main-layout itemMenuActive="44" tituloMenu="Transacciones por usuario">
        <div class="container">
          <br/>
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <form v-on:submit.prevent="getTransactions">
                    <h2>Reporte de transacciones por usuario</h2>
                    <div style="display: flex">
                      <div class="column is-3">
                        <b-field label="Fecha:" :message="validateForm.fecha.message" :type="validateForm.fecha.type">
                          <b-datepicker
                              placeholder="Seleccione fecha..."
                              v-model="model.fecha"
                              range :min-date="minDate" :max-date="maxDate">
                          </b-datepicker>
                        </b-field>
                      </div>
                      <div class="column is-3">
                        <b-field label="Tipo de documento" :message="validateForm.tipo.message"
                                 :type="validateForm.tipo.type">
                          <b-select placeholder="Tipo de documento" v-model="model.tipo">
                            <option :key="key" :value="key" v-for="(value, key) in documentTypes">{{ value }}</option>
                            <!--                            <option value="cc">Cedula de Ciudadanía</option>-->
                            <!--                            <option value="ce">Cedula de Extranjería</option>-->
                            <!--                            <option value="nit">NIT</option>-->
                          </b-select>
                        </b-field>
                      </div>
                      <div class="column is-5">
                        <b-field label="Número de documento:" :message="validateForm.cedula.message"
                                 :type="validateForm.cedula.type">
                          <b-numberinput placeholder="Ingrese número de documento..."
                                         v-model="model.cedula" :controls="false"
                                         type="search"
                                         icon="magnify">
                          </b-numberinput>
                          <p class="control">
                            <b-button type="is-primary" label="Consultar" native-type="submit"/>
                          </p>
                        </b-field>
                      </div>
                    </div>
  
                  </form>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <div v-if="user" class="media">
                    <div class="media-content">
                      <p class="subtitle is-4">Se está consultando el usuario con {{ user.documentType }}:
                        {{ user.document }} </p>
                    </div>
                    <div v-if="data.length > 0 || face.length >0" class="uk-text-right">
                      <label class="uk-form-label"></label>
                      <b-button v-on:click="exportEXCEL">XLSX</b-button>
                    </div>
                  </div>
                  <hr>  
                  <div v-if="data.length > 0" class="uk-text-right">
                    <label class="uk-form-label"></label>
                    <b-button v-on:click="exportCSV">CSV</b-button>
                    <!--<b-button v-on:click="exportEXCELS">XLSX</b-button>-->
                  </div>
                  <TableCard title="Consulta Onboarding Digital" :haveFilter="true" :columns="columnsOnboardingTable" :info="data"
                             detailKey="id" :configFilter="configFilterOnboarding"
                             :tableType="4"></TableCard>
                  <hr>
                  <div v-if="face.length > 0" class="uk-text-right">
                    <label class="uk-form-label"></label>
                    <b-button v-on:click="exportCSVFace">CSV</b-button>
                    <!--<b-button v-on:click="exportEXCELF">XLSX</b-button>-->
                  </div>
                  <TableCard title="Consulta Reconocimiento Facial"
                             :haveFilter="true" :columns="columnsFaceRecognitionTable" :info="face"
                             detailKey="transactionid" :configFilter="configFilterFaceRecognition"
                             :tableType="2"></TableCard>
                  <hr>
                  <div v-if="registraduria.length > 0" class="uk-text-right">
                    <b-button v-on:click="exportCSVRegistraduria">CSV</b-button>
                  </div>
                  <TableCard title="Consulta Registraduria"
                           :haveFilter="true" :columns="columnsRegistraduria" :info="registraduria"
                           detailKey="id" :configFilter="configFilterRegistraduria" 
                           :tableConfig="tableConfig"></TableCard>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
        <br>
        <br>
        <br>
      </main-layout>
    </main>
  </template>
  
  <script>
  import MainLayout from "../../components/Layout/MainLayout";
  import TableCard from "../../components/cards/TableCard";
  import {
    COLUMNS_FACE_RECOGNITION_TABLE,
    CONFIG_FILTER_FACE_RECOGNITION,
    COLUMNS_ONBOARDING_TABLE_FINAN,
    COLUMNS_SECURE_ONBOARDING_TABLE,
    CONFIG_FILTER_ONBOARDING_FINAN,
    COLUMNS_REGISTRADURIA,
    CONFIG_FILTER_REGISTRADURIA,
  } from "../../assets/config/constants/TableConstants";
  import {mapActions} from "vuex";
  import {formatData, getDateRange} from "../../assets/config/utilities";
  import {DOCUMENT_TYPES} from "../../assets/config/constants/GeneralConstants";
  import {mapState} from "vuex";
  
  export default {
    name: "PorUsuarioFinan",
    components: {
      MainLayout,
      TableCard
    },
    data() {
      const today = new Date()
      return {
        maxDate: new Date(),
        minDate: new Date(today.getFullYear(), today.getMonth()-6, today.getDay()),
        data: [],
        login: [],
        creacion: [],
        face: [],
        cambio: [],
        recuperar: [],
        registraduria: [],
        isLoading: false,
        tableConfig: {
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: false,
        },
        fechaInicial: "",
        fechaFinal: "",
        model: {
          fecha: [],
          cedula: null,
          tipo: null,
        },
        validateForm: {
          fecha: {
            message: '',
            type: ''
          },
          cedula: {
            message: '',
            type: ''
          },
          tipo: {
            message: '',
            type: ''
          }
        },
        documentTypes: DOCUMENT_TYPES,
        columnsFaceRecognitionTable: COLUMNS_FACE_RECOGNITION_TABLE,
        configFilterFaceRecognition: CONFIG_FILTER_FACE_RECOGNITION,
        columnsOnboardingTable: COLUMNS_ONBOARDING_TABLE_FINAN,
        configFilterOnboarding: CONFIG_FILTER_ONBOARDING_FINAN,
        columnsDailyOnBoardingTable: COLUMNS_SECURE_ONBOARDING_TABLE,
        columnsRegistraduria: COLUMNS_REGISTRADURIA,
        configFilterRegistraduria:CONFIG_FILTER_REGISTRADURIA,
        user: null
      }
    },
    computed: {
      ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
    },
    methods: {
      ...mapActions(['_axios']),
      getTransactions() {
        this.fechaInicial = this.model.fecha[0];
        this.fechaFinal = this.model.fecha[1];
        if (!this.validate())
          return;
        this.isLoading = true;
        const options = {
          method: "post",
          uri: "finan/transacionesporusuario",
          data: {
            Cedula: `${this.model.cedula}`,
            FechaConsulta: getDateRange(this.model.fecha),
            Tipo: this.model.tipo,
          },
        };
        this._axios(options).then(res => {
          this.data = res.data.info.sop;
          this.face = formatData(res.data.info.face);
          this.registraduria = formatData(res.data.info.registraduria);
          this.user = {
            document: this.model.cedula,
            documentType: this.model.tipo.toUpperCase()
          }
          this.model = {
            fecha: [],
            cedula: null
          }
        }).finally(() => {
          this.cancelarLoading();
        })
      },
      cancelarLoading() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      },
      exportEXCEL() {
        this.exportFile("exportExcelFinan");
      },
      exportCSVFace(){
        this.exportFile("exportCSVFinanFace");
      },
      exportCSV() {
        this.exportFile("exportCSVFinanSOP");
      },
      exportEXCELS(){
        this.exportFile("exportEXCELFinanSOP");
      },
      exportCSVRegistraduria(){
        this.exportFile("exportCSVRegistraduriaUser");
      },
      exportEXCELF(){
        this.exportFile("exportEXCELFinanFace");
      },
      exportFile(type) {
        if (this.fechaInicial !== "") {
          let fechaInicial = this.convertFecha(this.fechaInicial);
          let fechaFinal = this.convertFecha(this.fechaFinal);
          let tipo = this.user.documentType;
          let usuario = this.user.document
          tipo = tipo.toLowerCase();
          let routeData = `${this._HOST_SERVE}/finan/${type}/${fechaInicial}/${fechaFinal}/${tipo}/${usuario}`;
          window.open(routeData, "_blank");
        }
      },
      convertFecha: function (fecha) {
        let date = new Date(fecha).toISOString().slice(0, 10);
        return date;
      },
      validate() {
        if (this.model.fecha.length === 0) {
          this.validateForm.fecha.message = 'Debe ingresar un rango de fechas válido';
          this.validateForm.fecha.type = 'is-danger';
          return false;
        }
        if (this.model.cedula === null) {
          this.validateForm.cedula.message = 'Debe ingresar un número de documento válido';
          this.validateForm.cedula.type = 'is-danger';
          return false;
        }
        if (this.model.tipo === null) {
          this.validateForm.tipo.message = 'Debe ingresar una tipo de documento válido';
          this.validateForm.tipo.type = 'is-danger';
          return false;
        }
        this.validateForm.fecha.message = '';
        this.validateForm.fecha.type = '';
  
        this.validateForm.cedula.message = '';
        this.validateForm.cedula.type = '';
  
        this.validateForm.tipo.message = '';
        this.validateForm.tipo.type = '';
        return true;
      }
    }
  }
  </script>